<template>
  <div class="homepage-wrapper">
    <!--        <div id="preloader"></div>-->
    <Header/>
    <!-- header end -->

    <div class="slide-area slide-area-3 fix heading-area" data-stellar-background-ratio="0.6">
      <div class="display-table">
        <div class="display-table-cell">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <h1 style="color:#ffffff">Privacy and Terms</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start How to area -->
    <div class="how-to-area how-to-area-2 bg-color area-padding mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="section-headline text-center mb-4">
              <h1><strong>Terms and Conditions for TransCrypt</strong></h1>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="all-services">
            <p>These Standard Terms and Conditions shall manage your use of our website, TransCrypt, accessible at
              http://transcryptglobal.com.
              These Terms will be applied fully and affect your use of this Website. Please read these Terms of Use
              (“Terms”, “Terms of Use”)
              carefully before using the website operated by TransCrypt (“us”, “we”, or “our”).</p>
            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these
              Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or
              using the Service, you agree to be bound by these Terms. Should you disagree with any part of the terms
              then you may not access or use the Service.</p>
            <p>By entering into the agreement, you are appointing us as your agent to provide our Digital Tokens
              (hereinafter referred to as Cryptocurrency) services (services that allow you to use cryptocurrency in
              exchange for Fiat Currency, use cryptocurrency as a payment method for Money Remittances, and invest in
              cryptocurrency). You are also appointing us to provide nominee services, which means that we will act as
              your “nominee” for the purpose of holding your cryptocurrencies</p>
            <p>Minors or people below 18 years old are not allowed to use this Website and Service.</p>
            <br>
            <h2>Intellectual Property Rights</h2>
            <p>All editorial content, information, photographs, illustrations, artwork, and other graphic materials, and
              names, logos and trademarks on this Site are protected by copyright laws and/or other laws and/or
              international treaties, and belong to us and/or our suppliers, as the case may be. These works, logos,
              graphics, sounds or images may not be copied, reproduced, retransmitted, distributed, disseminated, sold,
              published, broadcast or circulated whether in whole or in part unless expressly permitted by us and/or our
              suppliers, as the case may be.</p>
            <p>Nothing contained on the Site should be construed as granting by implication, estoppel, or otherwise, any
              license or right to use any trademark displayed on the Site without our written permission. Misuse of any
              trademarks or any other content displayed on the Site is prohibited.
            </p>
            <p>We will not hesitate to take legal action against any unauthorized usage of its trademarks, name or
              symbols to preserve and protect its rights in the matter. All rights not expressly granted herein are
              reserved. Other product and company names mentioned herein may also be the trademarks of their respective
              owners.</p>
            <p>You are granted a limited license only for purposes of viewing the material contained on this
              Website.</p>
            <br>
            <h2>Restrictions</h2>
            <span>You are specifically restricted from all of the following:</span>
            <ol style="margin: 0 30px;">
              <li>
                publishing any Website material in any other media;
              </li>
              <li>
                selling, sub-licensing and/or otherwise commercializing any Website material;
              </li>
              <li>
                using this Website in any way that is or may be damaging to this Website;
              </li>
              <li>
                using this Website in any way that impacts user access to this Website;
              </li>
              <li>
                using this Website contrary to applicable laws and regulations, or in any way may cause
                harm to the Website, or to any person or business entity;
              </li>
              <li>
                engaging in any data mining, data harvesting, data extracting or any other similar
                activity in relation to this Website;
              </li>
              <li>
                using this Website to engage in any advertising or marketing
              </li>
            </ol>
            <p>Certain areas of this Website are restricted from being accessed by you and TransCrypt may further
              restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and
              password you may have for this Website are confidential and you must maintain confidentiality as well.
            </p>
            <br>
            <h2>Risks of Cryptocurrency</h2>
            <p>You should be aware that the risk of loss in trading or holding Cryptocurrency can be substantial. As
              with any asset, the value of Cryptocurrency can go up or down and there can be a substantial risk that you
              lose money buying, selling, holding, or investing in Cryptocurrency. You should carefully consider whether
              trading or holding Cryptocurrency is suitable for you in light of your financial condition.
            </p>
            <div>These risks include the following:</div>
            <ol style="margin: 0 30px;">
              <li>
                unlike normal money, when you buy or sell cryptocurrency you are dealing with something digital, not
                something tangible. This means that, like any other digital system, cryptocurrencies are at risk of
                being hacked or affected by technical problems. This could result in you losing your cryptocurrency or
                delay your ability to sell it;
              </li>
              <li>
                the regulation (or lack of regulation) of cryptocurrency or cryptocurrency exchanges can change at any
                time. Any change is likely to affect the value of any cryptocurrency we hold for you; and
              </li>
              <li>
                the value of cryptocurrency is exposed to high volatility risks and may vary up or down due to events
                such as, without limitation, technical changes applied to how the cryptocurrency works, market
                conditions or regulatory changes.
              </li>
            </ol>

            <br>
            <h2>Accounts</h2>
            <p>1. Opening of TransCrypt Account. In order to use the Services, you will need to apply for an Account by
              (a) providing your real name, email address and such other information as we may require, (b) selecting a
              password and (c) accepting the terms and conditions of this Agreement and the Privacy Policy. We reserve
              sole and absolute discretion to accept or reject any application for any reason or for no reason
              whatsoever, or limit the number of Account(s) that you may hold. Under no circumstances shall any of the
              Indemnified Persons be responsible or liable to you or any other person or entity for any direct or
              indirect losses (including loss of profits, business or opportunities), damages or costs arising from our
              decision to reject your application to open an Account.
            </p>
            <p>2. Identity Verification. You agree to provide us with the information we request for the purposes of
              identity verification and the detection of money laundering, terrorist financing, fraud, or any other
              financial crime and permit us to keep a record of such information. You will need to complete certain
              verification procedures before you are permitted to use the Services and your access to one or more
              Account(s) or the Services, and the limits that apply to your use of the Account(s) or the Services, may
              be altered as a result of information collected on an ongoing basis.</p>
            <p>3. Account usage. You must ensure that Account(s) registered under your name will not be used by any
              other person. Any breach of security, loss, theft or unauthorised use of your username, password or
              security information must be notified to us immediately. TransCrypt reserves the right to terminate,
              suspend or restrict your access to any Account(s) if there is reasonable suspicion by us that the person
              logged into your Account(s) is not you or if we suspect that the Account(s) have been or will be used for
              any illegal, fraudulent or unauthorised purposes. Under no circumstances shall any of the Indemnified
              Persons be responsible or liable for any direct or indirect losses (including loss of profits, business or
              opportunities), damages or costs suffered by you or any other person or entity due to any such
              termination, suspension or restriction of access to any Account(s).
            </p>
            <p>4. Safeguarding your Account details, password and API keys. You undertake to maintain at all times
              adequate security and control of all of your Account details, passwords, personal identification numbers
              (PINs), API keys, API secret keys, or any other codes that you use to access your Account(s) or the
              Services or to send any Instruction to us.</p>
            <p>5. Cryptocurrency custody. You will own the rights to the financial value of any cryptocurrency we buy
              for you. We will hold your cryptocurrencies on your behalf and you will have a right (called a “beneficial
              right”) to them. This means you can tell us when to sell it (within the limits of these terms and
              conditions). You have complete control of your cryptocurrencies, and we will only act upon instructions
              you give us. You will not be able to carry out transactions yourself.</p>
            <p>The cryptocurrency we buy for you is held in a “virtual account” that also holds cryptocurrencies for
              other TransCrypt customers. You will not have a separate cryptocurrency account. You can use TransCrypt
              dashboard to see the amount of cryptocurrency we’re holding for you.</p>
            <p>There won’t be any contractual relationship between you and our partnered cryptocurrency exchanges or any
              sub-custodian we may appoint.</p>
            <p>6. Closing your Account. You may terminate your Account(s) at any time. You will not be charged for
              terminating your Account(s). You authorise us to cancel or suspend any pending transactions at the time of
              cancellation.</p>
            <br>

            <h2>Eligibility</h2>
            <p>By registering to use a TransCrypt Account, you represent and warrant that you:
            </p>
            <ol style="margin : 0 30px;">
              <li>are at least 18 years old or of legal age to form a binding contract under applicable law and reside
                in a country in which the relevant TransCrypt Services are accessible;
              </li>
              <li>reside in Singapore;</li>
              <li>are an individual, legal person or other organization with full legal capacity and authority to enter
                into these Terms;
              </li>
              <li>have not previously been suspended or removed from using our Services;</li>
            </ol>

            <br>
            <h2>Transactions</h2>
            <p>To use our cryptocurrency services, you must have an account with TransCrypt. Your TransCrypt account is
              used to make payments for our cryptocurrency services. When you buy or sell cryptocurrency, we will make a
              payment into or out of it.
            </p>
            <p>1. Trading Rules. You agree to adhere to, and be bound by, the Trading Rules. TransCrypt may, from time
              to time at its sole and absolute discretion, amend, supplement or replace the Trading Rules, which shall
              be binding on you if you continue to maintain your Account(s) or use any of the Services after the
              effective date of any such amendment, supplement or replacement of the Trading Rules.</p>
            <p>2. Unauthorised Transactions. You are solely responsible for the control and use of your Account(s) and
              any Instruction sent from your Account(s) is deemed to be authorised and is binding on you. We are not
              obliged to verify the identity or authority of any person(s) using your Account(s) and we shall be at
              liberty to accept and place full reliance on any Instruction sent from your Account(s). If you notice any
              unauthorised or suspicious activity in your Account(s), you must notify us immediately. Under no
              circumstances shall any of the Indemnified Persons be responsible or liable for any direct or indirect
              losses (including loss of profits, business or opportunities), damages or costs suffered by you or any
              other person or entity, arising from or in connection with any of the Indemnified Persons' reliance on any
              Instruction sent from your Account(s).</p>
            <p>3. Retention of Transaction Information. To facilitate compliance with global industry standards for data
              retention, you agree to permit us to keep a record of all Transaction information for the lifetime of your
              Account(s) plus 6 years beyond the termination of your Account(s).
            </p>
            <p>4. Buying Cryptocurrency. You can buy cryptocurrency by clicking on “buy now”, you buy the cryptocurrency
              straight away. The procedure will be you telling us how much fiat currency you want to spend and we’ll let
              you know how much cryptocurrency you can buy (using the exchange rate at the time). Sometimes, for reasons
              beyond our control, we won’t be able to buy cryptocurrency for you. If this happens we’ll let you know
              and, if we can buy the cryptocurrency, we’ll take the fiat currency you told us you wanted to spend from
              your TransCrypt account and hold the cryptocurrency for you.
            </p>
            <p>5. Limits. We may limit the amount of cryptocurrency you may purchase. You will be informed on the amount
              of any spend/purchase limit before we accept your instruction.
            </p>
            <p>6. Fees. Both the buying and selling of cryptocurrencies through TransCrypt are subject to a transaction
              fee based on the amount of cryptocurrency sold or purchased.
            </p>

            <p>7. Selling Cryptocurrency. If you instruct us to sell cryptocurrency, we will sell the cryptocurrency at
              the exchange rate at the time your instruction is placed with Transcrypt; and add the proceeds from the
              sale to your TransCrypt account. You cannot receive the proceeds from a sale in any other way.</p>
            <p>8. Withdrawal. You can withdraw money from your Transcrypt account balance to your bank account. Upon
              withdrawal, you will need to verify your identity before we transfer the money to you</p>

            <br>
            <h2>Your Instruction and Authorisations
            </h2>

            <p>1. Instructions. You are solely responsible for accurately entering any Instruction, including but not
              limited to all the necessary information in order to enable us to carry out any Instruction. TransCrypt is
              not obliged to verify the accuracy or completeness of any such information or Instruction, or for
              monitoring or refusing to process duplicate Instructions.</p>
            <p>2. Refusing Your Instructions. Sometimes we might refuse your instruction to buy or sell cryptocurrency.
              If we do, we will not be responsible for any losses you suffer as a result. We may refuse your instruction
              if:</p>
            <ul style="margin : 0 30px">
              <li style="list-style-type: circle !important;">there is not enough cryptocurrency available in the
                market;
              </li>
              <li style="list-style-type: circle !important;">one of our partnered cryptocurrency exchanges is not
                available (for example, one of them could have a
                disrupted service);
              </li>
              <li style="list-style-type: circle !important;">we have good reason to suspect that your instruction was
                for illegal purposes (for example, to commit
                fraud); or
              </li>
              <li style="list-style-type: circle !important;">we have good reason to believe that your instruction could
                badly affect our reputation or goodwill.
              </li>
            </ul>
            <p>3. Cancelling Your Instructions. You cannot cancel any instruction to buy cryptocurrency made using “buy
              now”.</p>
            <p>Your Instructions are final, shall be irrevocable and unconditional and shall be binding on you, and such
              Instructions may be acted or relied upon by us irrespective of any other circumstances. As such, once you
              give any Instruction, you have no right to rescind or withdraw such Instruction without our written
              consent.</p>
            <p>Your Instruction shall not be considered to be received by TransCrypt unless and until it has been
              received by TransCrypt's server.
            </p>
            <p>Additionally, TransCrypt's records of all Instructions shall be conclusive and binding on you for all
              purposes.</p>

            <p>4. Our Discretion. You acknowledge and agree that TransCrypt may, in its sole and absolute discretion for
              any reason or no reason whatsoever, refuse to act upon or defer acting on any Instruction, or seek further
              information with respect to the Instruction. Under no circumstances shall any of the Indemnified Persons
              be responsible or liable for any direct or indirect losses (including loss of profits, business or
              opportunities), damages or costs suffered by you or any other person or entity, arising from or in
              connection with any of the Indemnified Persons' refusal or delay in acting upon any Instruction.
            </p>

            <p>5. Your Identity or Authority. We shall not be under any obligation to verify the identity or authority
              of any person giving any Instruction and the authenticity of such Instruction. Under no circumstances
              shall any of the Indemnified Persons be responsible or liable for any direct or indirect losses (including
              loss of profits, business or opportunities), damages or costs suffered by you or any other person or
              entity, arising from any of the Indemnified Persons relying or acting upon any Instruction which is given
              or purported to be given by you, regardless of the circumstances prevailing at the time of such
              Instruction, the nature of the arrangement, services or transaction made pursuant to such Instruction or
              the amount of money involved and notwithstanding any error, misunderstanding, fraud, forgery or lack of
              clarity or authorisation in the terms of such Instruction.</p>
            <p>6. Notification of Instructions. TransCrypt may transmit a notification to you upon receipt of any
              deposit or withdrawal Instruction from you. You are responsible to ensure that the details in any such
              notification transmitted to you are in accordance with your Instruction. Completion notification that an
              Instruction and/or a Transaction has been effected by TransCrypt may be provided to you via electronic
              communications. Any such completion notification transmitted to you shall be deemed to have been received
              by you immediately after transmission. You are required to contact us if you do not receive any completion
              notification within the time usually required for a similar notification to be received.</p>
            <br>

            <h2>No Recommendations or Investment Advice</h2>
            <p>TransCrypt provides self-directed investors with discount brokerage services, and does not make
              recommendations or offer investment advice of any kind. You are solely responsible for evaluating the
              merits and risks associated with the use of any Content provided through the Service before making any
              decisions based on such Content. You agree not to hold Robinhood or any Third-Party Provider liable for
              any possible claim for damages arising from any decision you make based on the Content or other
              information made available to you through the Service or any Third-Party Provider websites. Past
              performance data should not be construed as indicative of future results.</p>

            <br>

            <h2>Account Abandonment</h2>
            <p>An abandoned account refers to any inactive account through which no transactions have been
              processed for a minimum of six (6) months and for which most contact information (address,
              phone numbers, email address) is no longer valid.</p>

            <br>

            <h2>Your Content</h2>
            <p>In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video
              text, images or other material you choose to display on this Website. By displaying Your
              Content, you grant TransCrypt a non-exclusive, worldwide, irrevocable, sub licensable
              license to use, reproduce, adapt, publish, translate and distribute it in any and all
              media.</p>
            <p>Your Content must be your own and must not be invading any third-party’s rights. TransCrypt
              reserves the right to remove any of Your Content from this Website at any time without
              notice.</p>
            <br>
            <h2>Links to Other Websites</h2>
            <p>Our Service may contain links to third-party websites or services that are not owned or
              controlled by TransCrypt. We have no control over and assumes no responsibility for, the
              content, privacy policies, or practices of any third party web sites or services. You
              further acknowledge and agree that TransCrypt shall not be responsible or liable, directly
              or indirectly, for any damage or loss caused or alleged to be caused by or in connection
              with the use of or reliance on any such content, goods or services available on or through
              any such web sites or services.</p>
            <br>
            <h2>No Warranties</h2>
            <p>This Website is provided “as is” with all faults, and TransCrypt expresses no representations
              or warranties of any kind related to this Website or the materials contained on this
              Website. Also, nothing contained on this Website shall be interpreted as advising you. While
              we may use reasonable efforts to include accurate and up-to-date information on this Site,
              we make no warranties or representations as to its accuracy, timeliness or completeness.</p>
            <p>We do not warrant that this Site will always be accessible, uninterrupted, timely, secure,
              error-free or free from computer virus or other invasive or damaging code or that this Site
              will not be affected by any Acts of God or other force majeure events, including inability
              to obtain or shortage of necessary materials, equipment facilities, power or
              telecommunications, lack of telecommunications equipment or facilities and failure of
              information technology or telecommunications equipment or facilities.</p>
            <br>
            <h2>Limitation of Liability</h2>
            <p>In no event shall TransCrypt, nor any of its officers, directors, and employees, shall be
              held liable for anything arising out of or in any way connected with your use of this
              Website whether such liability is under contract. TransCrypt, including its officers,
              directors and employees shall not be held liable for any indirect, consequential or special
              liability arising out of or in any way related to your use of this Website.</p>
            <p>We shall not be liable for any direct, indirect, incidental, special, consequential or
              punitive damages, howsoever caused, resulting from your access to, use of or inability to
              use, reliance on or downloading from the site, or any delays, inaccuracies in the
              information or in its transmission including but not limited to damages for loss of business
              or profits, use, data or other intangible, even if we have been advised of the possibility
              of such damages.</p>
            <p>Notwithstanding our efforts to ensure that our Site/system is secure, you acknowledge that
              all electronic data transfers are potentially susceptible to interception by others. We
              cannot, and do not, warrant that data transfers pursuant to this Site, or electronic mail
              transmitted to and from us, will not be monitored or read by others.</p>
            <br>
            <h2>Indemnification</h2>
            <p>You hereby indemnify to the fullest extent TransCrypt from and against any and/or all
              liabilities, costs, demands, causes of action, damages and expenses arising in any way
              related to your breach of any of the provisions of these Terms.</p>
            <br>
            <h2>Severability</h2>
            <p>If any provision of these Terms is found to be invalid under any applicable law, such
              provisions shall be deleted without affecting the remaining provisions herein.</p>
            <br>
            <h2>Variation of Terms</h2>
            <p>TransCrypt is permitted to revise these Terms at any time as it sees fit, and by using this
              Website you are expected to review these Terms on a regular basis. Your continued use of the
              Site after any modifications to the Terms of Use indicates your acceptance of the modified
              Terms of Use.</p>
            <br>
            <h2>Assignment</h2>
            <p>TransCrypt. is allowed to assign, transfer, and subcontract its rights and/or obligations
              under these Terms without any notification. However, you are not allowed to assign,
              transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
            <br>
            <h2>Entire Agreement</h2>
            <p>These Terms constitute the entire agreement between TransCrypt and you in relation to your
              use of this Website, and supersede all prior agreements and understandings.</p>
            <br>
            <h2>Governing Law & Jurisdiction</h2>
            <p>This Site may be accessed from all countries around the world where the local technology
              permits. As each country has differing laws, by accessing this Site both we and you agree
              that the laws of the Republic of Singapore, without regard to the conflict of laws
              principles thereof, will apply to all matters relating to these Terms of Use.</p>
            <p>You accept and agree to submit to the exclusive jurisdiction of the courts of the Republic of
              Singapore in respect of any dispute or difference arising out of and/or in connection with
              these Terms of Use.</p>
            <br>
            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms, please
              <router-link to="/support">contact us</router-link>
              .
            </p>
            <br>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="section-headline text-center mb-4">
                  <h1><strong>Privacy Policy</strong></h1>
                </div>
              </div>
            </div>
            <p>Our Privacy Policy is downloadable <a
                href="/assets/remittance-assets/PrivacyStatement-TransCrypt.pdf" target="_blank"
                rel="noopener noreferrer">here</a>.</p>
            <br>
            <h2>Scope and Consent</h2>
            <p>We want you to understand why and how TransCrypt (“TransCrypt”, “we”, “us” and/or “our”)
              collects, uses, discloses and or/processes your Personal Data (as defined below) under
              Singapore’s Personal Data Protection Act 2012 (the “PDPA”).</p>
            <p>This Privacy Policy (“Policy”) applies to you and all Individuals (as defined below) who
              provide TransCrypt with Personal Data or whose Personal Data is otherwise collected, used,
              processed, stored and/or disclosed by TransCrypt in connection with and/or for the purposes
              of its operations.</p>
            <p>This Policy supplements but does not supersede or replace any previous consent which you may
              have provided to TransCrypt, nor does it affect any legal rights that TransCrypt may have
              regarding the collection, use, processing and/or disclosure of any Individual’s Personal
              Data.</p>
            <p>TransCrypt may from time to time update this Policy to ensure that it is consistent with our
              business needs or to accommodate amendments to applicable legal or regulatory requirements.
              All updates to this Policy will be published online at www.transcryptglobal.com (the
              “TransCrypt Website”).</p>
            <p>Notification of any material revisions will also be published on the TransCrypt Website. You
              shall be deemed to have accepted the Policy as amended by continuing your relationship with
              TransCrypt after any amendments have been published on TransCrypt Website.</p>
            <p>This Policy forms part of the terms and conditions, if any, governing your specific
              relationship with TransCrypt (“Terms and Conditions”) and it should be read in conjunction
              with the Terms and Conditions. In the event of any conflict or inconsistency between the
              provisions of this Policy and the Terms and Conditions, the provisions of the Terms and
              Conditions shall prevail to the fullest extent permissible by law.</p>
            <br>
            <h2>1. Definitions</h2>
            <span>For the purposes of this Policy:</span>
            <ol style="margin : 0 30px">
              <li>“Individual” (or “you”) means a natural person, whether living or deceased and
                “Individuals” (and “your”) shall be construed accordingly;
              </li>
              <li>“Personal Data” means data that is capable of identifying an Individual, whether on its
                own or in conjunction with other data accessible to TransCrypt;
              </li>
              <li>“Personnel” means any Individual engaged under a contract of service with TransCrypt
                including permanent or temporary employees as well as trainees and interns engaged by
                TransCrypt from time to time; and
              </li>
              <li>“Potential Personnel” means any Individual who has submitted an application to be
                engaged by TransCrypt as Personnel.
              </li>

            </ol>
            <br>
            <h2>2. Purposes for Collection, Use and Disclosure of Personal Data</h2>
            <p>TransCrypt will only collect, use and disclose Personal Data from/or related to an Individual
              that is reasonably considered necessary for the relevant purposes underlying such
              collection, use or disclosure, which may include but is not limited to the following:</p>
            <ol style="margin : 0 30px">
              <li>Provide our Services (including customer support);</li>
              <li>Process transactions and send notices about your transactions;</li>
              <li>Resolve disputes, collect fees, and troubleshoot problems;</li>
              <li>Communicate with you about our Services and business and to inform you of matters that
                are
                important for your account and/or use of the Sites. We also use your personal data to
                respond to any questions, comments or requests you filed with us and the handling of any
                complaints;
              </li>
              <li>Comply with applicable laws and regulations;</li>
              <li>Establish, exercise and defend legal claims;</li>
              <li>Monitor and report compliance issues;</li>
              <li>Customize, measure, and improve our business, the Services, and the content and layout
                of
                our website and applications (including developing new products and services; managing
                our communications; determining the effectiveness of our sales, marketing and
                advertising;
                analyzing and enhancing our products, services, websites and apps; ensuring the security
                of
                our networks and information systems; performing accounting, auditing, invoicing,
                reconciliation and collection activities; and improving and maintaining the quality of
                our
                customer services);
              </li>
              <li>Perform data analysis;</li>
              <li>Deliver targeted marketing, service update notices, and promotional offers based on your
                communication preferences, and measure the effectiveness of it. To approach you via
                email
                for marketing purposes, we request your consent, unless it is not required by law. You
                always have the option to unsubscribe from our mailings, e.g., via the unsubscribe link
                in
                our newsletter;
              </li>
              <li>Perform risk management, including comparing information for accuracy and verify it with
                third parties and protect against, identify and prevent fraud and other prohibited or
                illegal activity, claims and other liabilities; and
              </li>
              <li>Enforce our contractual terms.</li>

            </ol>
            <br>
            <h2>3. What Information May We Collect?</h2>
            <p>The exact Personal Information which we may collect from you will differ depending on if you
              are visiting a TransCrypt website/social media account, or if you are on-boarded or in the
              process of being on-boarded for the use of our Services. Some of the Personal Data which we
              may collect through your interaction with us, depending on the mode of interaction, may
              include your/the:</p>
            <ol style="margin : 0 30px">
              <li>First and last name;</li>
              <li>Country and city of residence;</li>
              <li>Location data;</li>
              <li>Job title;</li>
              <li>Nationality of legal representatives and senior management members;</li>
              <li>% shares/voting rights in the company;</li>
              <li>Phone number;</li>
              <li>ID number;</li>
              <li>Date of birth;</li>
              <li>Date of birth and nationality of individuals owning or controlling directly and
                indirectly more than 10% in the company;
              </li>
              <li>First and last name and/or billing first and last name and/or shipping name;</li>
              <li>Billing address (street, city, state, country, zip code) and/or shipping address;</li>
              <li>Email and/or billing email and/or shipping email;</li>
              <li>Phone and/or billing phone number and/or shipping phone number;</li>
              <li>Billing company and/or shipping company;</li>
              <li>Username;</li>

              <li>User ID;</li>
              <li>Shopping cart items information: for each item, the SKY, price, quantity and name of the
                item;
              </li>
              <li>Shopping cart checkout information: shipping cost, tax cost, shipping discount;</li>
              <li>Any online identifier; and</li>
              <li>Any factors that may be used to identify you.</li>

            </ol>
            <p>This is not an exhaustive list. Depending on how you interact with us, we may collect
              additional information, as required.</p>
            <p>In case Personal Data that you provide to us or we collect is considered special categories
              of Personal Data or judicial data under applicable data protection laws, we only process
              them to the extent permitted by applicable law. Special categories of Personal Data may
              include Personal Data from which we can determine or infer an individual’s racial or ethnic
              origin, political opinions, religious or, trade union membership, genetic data, data
              concerning health, sexual life or sexual orientation. Judicial data may include data
              relating to criminal convictions and offenses, including information concerning the
              commission or alleged commission of a criminal offense.</p>
            <p>Personal Data does not include data where any potential identifiers have been irreversibly
              removed (anonymous data).</p>
            <br>
            <h2>4. How TransCrypt Collects Personal Data</h2>
            <p>Generally, TransCrypt may collect Personal Data from you in one or more of the following ways
              or circumstances:</p>
            <ol style="margin : 0 30px">
              <li>Information provided directly: You may be asked to provide Personal Data when you visit
                or use some parts of our websites and/or services. You do not have to provide us with
                your Personal Data; however, it could mean that you are not able to access some parts of
                our website or use our services.
              </li>
              <li>Information collected automatically: Some information is collected automatically, such
                as an IP address and device type, when you visit our websites or use our services. This
                information is useful to us as it provides us with a better understanding of how you are
                interacting with our websites and services so that we can continue to provide you with
                the best experience possible.
              </li>
              <li>Information from third parties: The majority of information that we collect is
                collected directly from you. Some data may be collected from other sources, such as
                information that is available in the public domain. We use this information to
                supplement the Personal Data that we have collected from you.
              </li>
            </ol>
            <br>
            <h2>5. How We Use Personal Data and Other Information</h2>
            <p>We may use personal and other information for our legitimate business interests, to the
              extent permitted by applicable law, including but not limited to:</p>
            <ol style="margin : 0 30px">
              <li>Complete your transactions, respond to your questions, and provide you with customer
                service;
              </li>
              <li>Send you transactional and other administrative messages;</li>
              <li>Personalize your experience when you use our services;</li>
              <li>Operate and grow our business (e.g., conduct data analysis; audit our activities;
                develop new products; enhance, improve and modify our services; identify usage trends;
                determine the effectiveness of our promotional campaigns);
              </li>
              <li>Monitor and prevent fraud, money laundering, abuse, and other actual and potential
                prohibited or illegal activities;
              </li>
              <li>Meet legal, auditing, regulatory, insurance, security and processing requirements;</li>
              <li>Report to credit bureaus;</li>
              <li>Respond to court orders and legal investigations;</li>
              <li>Deliver marketing communications to you about our services and other companies’
                services, including offers, coupons or incentives we believe may be of interest to you;
              </li>
              <li>Comply with applicable laws, which may include laws outside your country of residence;
              </li>
              <li>Respond to requests from public and government authorities, which may include
                authorities outside your country of residence;
              </li>
              <li>Cooperate with law enforcement, or for other legal reasons</li>
              <li>Enforce our terms and conditions; and</li>
              <li>Protect our rights, privacy, safety or property, and/or that of our affiliates, you or
                others. We may also use information in other ways with your consent or as required by
                applicable law.
              </li>
            </ol>
            <br>
            <h2>6. How We Protect Your Data</h2>
            <p>TransCrypt shall make reasonable security arrangements to prevent unauthorized access,
              collection, use, disclosure, copying, modification, disposal or similar risks to Personal
              Data in its possession.</p>
            <p>If TransCrypt transfers Personal Data outside of Singapore, TransCrypt will take reasonable
              steps to ensure that such Personal Data transferred receives a standard of protection
              comparable to the protection received under the PDPA and such transfer shall be subject to
              this Policy.</p>
            <p>TransCrypt will ensure that third parties who receive Personal Data from TransCrypt protect
              such Personal Data in a manner consistent with this Policy and not use such Personal Data
              for any purposes other than those specified by TransCrypt, by incorporating appropriate
              contractual terms in its written agreements with third parties.</p>
            <p>TransCrypt is not responsible in any way for the security and/or management of Personal Data
              shared by you with third party websites accessible via links on TransCrypt’s website.</p>
            <p>Please know, however, that no data transmission or storage system can be guaranteed to be
              100% secure. If you have reason to believe that your interaction with us is no longer
              secure, please contact us immediately.</p>
            <br>
            <h2>7. How We Share Data with Third Parties</h2>
            <p>We share and disclose information with:</p>
            <ol style="margin : 0 30px">
              <li>Our internal departments, in order to carry out the purposes outlined above, Personal
                Data you provide shall be disclosed to our compliance team and other internal functions
                on a need to know basis or where we have a legitimate interest in doing so;
              </li>
              <li>Our vendors that provide us with services related to information technology, such as
                website hosting, data analysis, payment processing, order fulfillment, information
                technology and related infrastructure provision, customer service, and email delivery;
              </li>
              <li>Our vendors that provide us with services related to our marketing communications and
                campaigns, consistent with your choices, including any applicable choices we provide for
                you to opt into such sharing;
              </li>
              <li>Your social media connections, other website users and your social media account
                providers;
              </li>
              <li>Other third parties in the event of a reorganization, merger, sale, joint venture,
                assignment, transfer or other disposition of our business, assets or stock, or in any
                bankruptcy or similar proceedings; and
              </li>
              <li>Others as required by law. We reserve the right to disclose any Personal Data you have
                provided if we are compelled to do so by a court of law or requested to do so by a
                governmental entity or if we determine it is necessary or desirable to comply with the
                law or to protect or defend our rights or property in accordance with applicable laws.
                We also reserve the right to retain to comply with any specific record retention laws
                that apply.
              </li>
            </ol>
            <br>
            <h2>8. Request to Withdraw Consent</h2>
            <p>You have the right to withdraw your consent to the collection, use and/or disclosure of your
              Personal Data in the possession of TransCrypt by submitting your request to TransCrypt’s
              Data Protection Officer at <a href="mailto:compliance@transcryptglobal.com">compliance@transcryptglobal.com</a>
              at any time.</p>
            <p>We will process your request for the withdrawal of consent within a reasonable period of time
              from such a request being made. After that time, we will not collect, use and/or disclose
              your Personal Data in the manner stated in your request.</p>
            <p>Your withdrawal of consent could affect the services that we are able to provide to you.
              Depending on the extent of your withdrawal of consent for us to process your Personal Data,
              it may mean that we are not able to continue with our existing business relationship</p>
            <p>With regards to data that is collected by cookies, you may disable the use of cookies on your
              internet browser when accessing TransCrypt’s Website. However, disabling the use of cookies
              may result in the loss of functionality, restrict your use of the website and/or delay or
              affect the way in which TransCrypt’s website operates.</p>
            <br>
            <h2>9. Accuracy of Personal Data</h2>
            <p>Information voluntarily provided by you to TransCrypt shall be deemed complete and
              accurate.</p>
            <p>TransCrypt will take reasonable steps to verify the accuracy of Personal Data received at the
              point of collection, but you will remain primarily responsible and liable to ensure that all
              Personal Data submitted by you to TransCrypt is complete and accurate.</p>
            <p>TransCrypt will also take reasonable steps to periodically verify the Personal Data in its
              possession, taking into account the scope of its operations. However, you remain responsible
              for notifying TransCrypt, from time to time, of any applicable changes to your Personal
              Data. You may notify support team of any changes to your Personal Data by email at
              <a href="mailto:support@transcryptglobal.com">support@transcryptglobal.com</a>, or you may
              contact the Personal Data Officer by email at
              <a href="mailto:compliance@transcryptglobal.com">compliance@transcryptglobal.com</a> at any
              time.</p>
            <p>TransCrypt shall not be held liable for any inability on its part to provide services to you
              if you fail to ensure that your Personal Data submitted to TransCrypt is complete and
              accurate.</p>
            <br>
            <h2>10. Access To and Correction of Personal Data</h2>
            <p>You may request :</p>
            <ol style="margin : 0 30px">
              <li>access to any Personal Data that is currently in our possession or control; and/or</li>
              <li>correction of any data that is currently in our possession or control by contacting us
                by email at <a
                    href="mailto:support@transcryptglobal.com">support@transcryptglobal.com</a>.
              </li>
            </ol>
            <p>For your protection, we may need to verify your identity before implementing your
              request.</p>
            <p>When you request access to your Personal Data within our possession or control, we will seek
              to provide you with the relevant information within 10 business days.</p>
            <p>Upon your written request, we will correct any inaccurate Personal Data that is within our
              possession or control within 10 business days.</p>
            <p>With your consent, once we have corrected your Personal Data, we will send the corrected
              Personal Data to all organizations to which your Personal Data was disclosed by TransCrypt
              within a year before the correction was made, unless that organization does not require the
              corrected Personal Data for any legal or business purpose.</p>
            <p>TransCrypt is not required, under the PDPA, to provide access and correction to Personal Data
              in certain exempted situations as set out in the PDPA.</p>
            <br>
            <h2>11. Retention of Data</h2>
            <p>The length of time that we keep your Personal Data depends on what it is and whether we have
              an ongoing business need to retain it.</p>
            <p>We will only retain your data for as long as we have a business relationship with you and for
              a period of time afterwards where we have an ongoing business need to retain it, or where we
              are required by law to retain it.</p>
            <p>Upon the expiry of time relating to any business needs or legal requirements to retain your
              Personal Data we will ensure that your Personal Data is deleted or anonymized.</p>
            <br>
            <h2>12. Making a Complaint</h2>
            <p>You are welcome to contact us if you have any complaint or grievance about how we are
              handling any Personal Data in our control or possession.</p>
            <p>To make a complaint you can:</p>
            <ol style="margin : 0 30px">
              <li>Contact our Data Protection Officer via email at <a href="mailto:compliance@transcryptglobal.com">compliance@transcryptglobal.com</a>;
              </li>
              <li>Visit us in person at our Singapore office during our office hours (9:00am to 6:00pm).</li>
            </ol>


          </div>
        </div>
      </div>
    </div>
    <!-- End How to area -->


    <!-- Start Footer Area -->
    <Footer/>
    <!-- End Footer Area -->
  </div>
</template>
<script>
import Footer from "../layouts/HomepageFooter"
import Header from "../layouts/HomepageHeader"

export default {
  name: 'homepage',
  components: {
    Footer,
    Header
  },
  data() {
    return {
      debugConsole: false
    }
  },
  mounted() {
    if (this.debugConsole) console.debug("Privacyandpolicytermsmounted");
    require('@/assets/js/main.js');

    //check if mean container is loaded
    if (this.debugConsole) console.log("Mean container loaded", window.jQuery('.mean-container'))
    if (window.jQuery('.mean-container').length === 0) {
      window.jQuery('nav#dropdown').meanmenu()
    }

  }
}

</script>
<style scoped>
ul > li {
  list-style-type: none;
}
</style>
